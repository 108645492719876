<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg10 xl7>
        <v-data-table
          dense
          :headers="headers"
          :items="compNotasLista"
          class="elevation-10"
          :search="search"
          :loading="!compNotasProgress"
          loading-text="Carregando..."
          :footer-props="{
            'items-per-page-options': [10, 20, 30],
          }"
          sortBy="id"
          sort-desc
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-note-multiple-outline</v-icon>
              <v-toolbar-title>Notas</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSelecionarNotaEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Nota</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="auto" md="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSelecionarNotaEdicao(item)"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import DatasMixin from "@/mixins/DatasMixin";
import moment from "moment";

const { mapState, mapActions } = createNamespacedHelpers("utilNotas");

export default {
  // components: { ConfirmDialogue },
  mixins: [DatasMixin],
  name: "UtilNotasLista",
  data: () => ({
    search: "",
    // dialog: false,
    headers: [
      { text: "Código", value: "id", align: "start" },
      { text: "Título", value: "titulo", align: "start" },
      // {
      //   text: "Nota",
      //   align: "start",
      //   sortable: true,
      //   value: "nota",
      // },
      // { text: "Criado", value: "criado" },
      { text: "Ações", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState(["staErro", "staNotas", "staNotaSelecionado"]),

    compNotasLista() {
      return this.$store.state.utilNotas.staNotas;
    },
    compNotasProgress() {
      // console.log(!this.$store.state.utilNotas.staNotas.length);
      return this.$store.state.utilNotas.staNotas;
    },
  },

  created() {
    register(this.$store);
    this.actListarNotas(this.$store.state.login.licenca);
  },

  watch: {
    erro() {
      if (this.staErro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.staErro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  mounted() {
    //this.obterNotas();
  },

  methods: {
    ...mapActions(["actListarNotas", "actSelecionarNota", "actResetarNota"]),

    metPermissao(field) {
      if (this.perm.some((t) => t.field == field)) {
        const permObject = this.perm.filter((t) => t.field == field);
        if (permObject[0].group[0] == this.$store.state.login.usuario.grupo) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    formataData(data) {
      return moment(data).utc().format("DD-MM-YYYY");
    },
    formataDataIn(data) {
      return moment(data).utc().format("YYYY-MM-DD");
    },

    // metLixeiraColor(lixeira) {
    //   if (lixeira) return "orange darken-2";
    //   else return "red darken-1";
    // },

    // metParaLixeira(nota) {
    //   this.actLixeiraNota({
    //     nota: nota,
    //     licenca: this.$store.state.login.licenca,
    //     modificacao: this.$store.state.login.usuario.usuario,
    //   });
    // },

    initialize() {
      /*       if (this.notas === null) {
        this.notas = null;
      } */
    },
    login() {
      this.$router.push({ name: "Login" });
    },
    /* novoNota() {
      if (this.staNotaSelecionado) {
        this.resetarNotaSelecionado();
        return;
      }
    }, */

    async metSelecionarNotaEdicao(nota) {
      // this.actSelecionarNota({ nota });
      // this.$router.push({ name: "UtilNotasForm" });
      if (nota != undefined) {
        await this.actSelecionarNota({
          id: nota.id,
          licenca: this.$store.state.login.licenca,
        });
      } else {
        this.actResetarNota();
      }
      await this.$router.push({ name: "UtilNotasForm" });
    },

    paraAtivacao(nota) {
      // if (nota.data_cadastro != "0000-00-00") {
      nota.data_cadastro = this.formataDataIn(nota.data_cadastro);
      // }
      // if (nota.ultima_compra != "0000-00-00") {
      nota.ultima_compra = this.formataDataIn(nota.ultima_compra);
      // }
      this.ativarNota({ nota });
      // this.actListarNotas();
    },
    resetarNota() {
      this.resetarNotaSelecionado();
    },
    metVoltar() {
      this.$router.back();
    },
    metRecarregar() {
      this.actListarNotas(this.$store.state.login.licenca);
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },
  },
};
</script>

<style></style>
